import { firestore } from "../firebase";
import stableStringify from "fast-json-stable-stringify";
import localforage from "localforage";

export default class Utilities {
  constructor() {
    const serverSide = typeof window === "undefined";

    this.relativeTime = new Intl.RelativeTimeFormat(
      serverSide ? "en-US" : navigator.language,
      {
        style: "long", // // long, short, narrow
        numeric: "always"
      }
    );
    this.formatNumber = new Intl.NumberFormat(
      serverSide ? "en-US" : navigator.language,
      {
        notation: "compact"
      }
    ).format;

    if (serverSide === false) {
      this.store = localforage.createInstance({
        name: "cache",
        storeName: "bytez"
      });
    }
  }
  cache = {
    get: key => this.store.getItem(key),
    set: (key, value) => this.store.setItem(key, value)
  };
  makeId = async obj => {
    if (typeof window !== "undefined") {
      const encoder = new TextEncoder();
      const data = encoder.encode(stableStringify(obj));
      const arrayBuffer = await crypto.subtle.digest("SHA-1", data);
      let hexString = "";

      for (const byte of new Uint8Array(arrayBuffer)) {
        hexString += byte.toString(16).padStart(2, "0");
      }
      // set id as the sha-1 hex string
      return hexString;
    }
  };
  paperTime(date) {
    const paperDate = new Date(date);
    const currentDate = new Date();
    const lastYear = new Date(currentDate);

    lastYear.setFullYear(currentDate.getFullYear() - 1);

    if (paperDate < lastYear) {
      return paperDate.getFullYear();
    } else {
      return this.timeAgo(paperDate);
    }
  }
  getDomain(url) {
    const { hostname } = new URL(url);
    const hasSubdomain = hostname.split(".").length !== 2;

    return hasSubdomain
      ? hostname.slice(hostname.indexOf(".", 2) + 1)
      : hostname;
  }
  formatList = array =>
    `${array.slice(0, -1).join(", ")}, and ${array[array.length - 1]}`;

  publishMessage = ({ action, entity, topic, message }) =>
    firestore
      .doc("workers/publish/messages", `client:${action}:${entity}`)
      .set({ topic, message });

  timeUntil = date => {
    const msSinceDate = date.getTime() - Date.now();
    const absoluteTime = Math.abs(msSinceDate);
    // "year", "quarter", "month", "week", "day", "hour", "minute", "second"
    const millisecondsIn = {
      year: 3.154e10,
      // quarter: 7.884e9,
      month: 2.628e9,
      week: 6.048e8,
      day: 8.64e7,
      hour: 3.6e6,
      minute: 60e3,
      second: 1e3,
      ms: 1
    };

    for (var unit in millisecondsIn) {
      var ratio = absoluteTime / millisecondsIn[unit];

      if (1 <= ratio) {
        return {
          unit,
          diff: Math.round(msSinceDate / millisecondsIn[unit])
        };
      }
    }
    return {};
  };
  timeAgo = date => {
    const { unit, diff } = this.timeUntil(date);

    return !unit || unit === "ms"
      ? "now"
      : this.relativeTime.format(diff, unit);
  };

  // under 60 = seconds
  // above 60 is mins
  // above 3600 is hours
  // above 86400 is days
  timeUnit = duration => {
    if (86400 < duration) {
      var unit = "day";
      var ratio = duration / 86400;
    } else if (3600 < duration) {
      unit = "day";
      ratio = duration / 3600;
    } else if (60 < duration) {
      unit = "min";
      ratio = duration / 60;
    } else {
      unit = "sec";
      ratio = duration;
    }
    return `${ratio.toFixed(0)} ${unit}${ratio < 2 ? "" : "s"}`;
  };
  isValidEmail = / /.test.bind(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  );

  sendEmail = email => firestore.collection("mail").add(email);
}
const regExpLineBreaks = /\n|\s\s/g;
const regExpSentences = /(?=\s?[!.?\w")]+)[!|.|?](?=$|\s[A-Z])/g;
const regExpPunctuations = /[.,/#!$%^&*;:{}=\-_`~()]/g;

// Article is a string of text to summarize
export function textRank(text, numberOfSentences = 3) {
  if (text?.constructor !== String) {
    return console.warn("TextRank ERROR:", "Article Must Be Type String");
  }

  const graph = { V: {}, E: {}, numVerts: 0 };

  try {
    setupGraph(graph, text);
    iterate(graph);

    return extractSummary(graph, numberOfSentences);
  } catch (error) {
    console.error(error);
  }
}
function setupGraph(graph, text) {
  graph.V = textPreprocesser(text);
  graph.numVerts = graph.V.length;

  for (let vertex, Si, i = 0, j = graph.numVerts, k, Sj; i < j; i++) {
    vertex = graph.V[i];
    vertex.score = Math.random() * 10 + 1;
    vertex.id = i;
    Si = vertex;

    for (k = 0; k < j; k++) {
      if (i !== k) {
        if (graph.E[i] === undefined) {
          graph.E[i] = {};
        }

        Sj = graph.V[k];
        graph.E[i][k] = similarityScoring(Si, Sj);
      }
    }
  }
}

function similarityScoring(Si, Sj) {
  const Si_tokens = Si.tokens;
  const Sj_tokens = Sj.tokens;
  let i = Sj_tokens.length;
  let wordOverlapCount = 0;

  while (i--) {
    if (Si_tokens.includes(Sj_tokens[i])) {
      wordOverlapCount++;
    }
  }

  return (
    wordOverlapCount / (Math.log(Si_tokens.length) + Math.log(Sj_tokens.length))
  );
}

function iterate(graph, iterations = 0) {
  let iterateAgain = true;

  for (let d = 0.85, delta = 0.0001, i = 0, j = graph.V.length; i < j; i++) {
    var vertex = graph.V[i];
    var score_0 = vertex.score;
    var vertexNeighbors = graph.E[i];
    var summedNeighbors = 0;

    for (var neighborIndex in vertexNeighbors) {
      var wji = graph.E[i][neighborIndex];
      var outNeighbors = graph.E[neighborIndex];
      var summedOutWeight = 1;

      for (var outIndex in outNeighbors) {
        summedOutWeight += outNeighbors[outIndex];
      }

      summedNeighbors += (wji / summedOutWeight) * graph.V[neighborIndex].score;
    }

    var score_1 = 1 - d + d * summedNeighbors;

    graph.V[i].score = score_1;

    if (Math.abs(score_1 - score_0) <= delta || 100 < iterations) {
      iterateAgain = false;
    }
  }

  if (iterateAgain) {
    iterate(graph, iterations + 1);
  }
}
const byScore = (a, b) => a.score - b.score;
const byId = (a, b) => a.id - b.id;

function extractSummary(graph, numberOfSentences) {
  let sentences = [];
  let i = graph.V.length;

  while (i--) {
    sentences[i] = graph.V[i];
  }

  sentences.sort(byScore);
  sentences = sentences.slice(0, numberOfSentences);
  sentences.sort(byId);

  let [{ sentence: summary }] = sentences;

  for (let i = 1, j = sentences.length; i < j; i++) {
    summary += sentences[i].sentence;
  }

  return summary.trim();
}
const exists = sentence => sentence;
const toTokens = sentence =>
  sentence
    .toLowerCase()
    .replace(regExpPunctuations, "")
    .split(" ")
    .filter(exists);

function textPreprocesser(text) {
  const delimiter = "|bytez_delimiter|";
  const sentences = text
    .replace(regExpLineBreaks, " ")
    .trim()
    .replace(regExpSentences, `$&${delimiter}`)
    .split(delimiter)
    .filter(sentence => sentence.trim());
  const tokens = sentences.map(toTokens);
  const vertices = [];

  for (let i = 0, j = sentences.length; i < j; i++) {
    vertices[i] = { sentence: sentences[i], tokens: tokens[i] };
  }

  return vertices;
}
