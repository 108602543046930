"use client";
import { reaction } from "mobx";

import { subscribe, convertToID } from "../../service/graph";
import { fetchFromApiServer, fetchFromApiServerRaw } from "../../service/graph";
import { firestore } from "../firebase";

export default class LLM {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    let unsubscribeToQuestions;
    let unsubscribeToFirstTLDR;

    reaction(
      () => this._.reader.config?.disable?.llm ?? false,
      this.set.disable
    );
    reaction(
      () => [
        this._.user.loaded,
        // this.tab,
        this._.reader.publisher,
        this._.reader.paperID,
        this._.reader.whiteLabelled === false
      ],
      async ([loaded, publisher, paperID, notWhiteLabeled]) => {
        if (
          loaded &&
          // tab === "paper" &&
          publisher &&
          paperID &&
          notWhiteLabeled
        ) {
          unsubscribeToFirstTLDR?.();
          unsubscribeToQuestions?.();
          this.reset();

          unsubscribeToQuestions = this._.user.data.query(
            {
              collection: "questions",
              orderBy: [["created", "desc"]],
              where: [
                ["props.publisher", "==", publisher],
                ["props.paperID", "==", paperID]
              ]
            },
            ({ docs }) => {
              this.set.questionsPaper(
                docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
                  ref: firestore.doc(doc.ref.parent.path, doc.id)
                }))
              );
            }
          );
          const results = await fetchFromApiServer({
            path: `question/availability/${publisher}/${paperID}`
          });
          const available = results?.available ?? false;

          // if after awaiting server response, on the same paper
          if (
            this._.reader.publisher === publisher &&
            this._.reader.paperID === paperID
          ) {
            this.set.available(available);

            if (available && this._.reader.paperNode?.summaries?.length === 0) {
              unsubscribeToFirstTLDR = subscribe(
                {
                  first: 1,
                  query: this._.gql.get("paper"),
                  variables: { id: convertToID({ paperID, publisher }) },
                  pollInterval: 3e3
                },
                results => {
                  const paperNode = results?.data?.papers?.[0];

                  if (
                    1 <= paperNode?.summaries?.length &&
                    this._.reader.publisher === publisher &&
                    this._.reader.paperID === paperID
                  ) {
                    var paper = { ...paperNode };
                    try {
                      paper.authorArray = JSON.parse(paper.authors);
                    } catch (error) {
                      console.error(error);
                    }

                    this._.reader.set.paperNode(paper);
                    unsubscribeToFirstTLDR();
                  }
                }
              );
            }
          }
        }
      }
    );
  }
  set = {
    // tab: tab => {
    //   this.tab = tab;
    // },
    filler: filler => {
      this.filler = filler;
    },
    available: (available = false) => {
      this.available = available;
    },
    disable: (disable = false) => {
      this.disable = disable;
    },
    questionsPaper: (questionsPaper = []) => {
      this.questionsPaper = questionsPaper;
    },
    questionsCode: (questionsCode = []) => {
      this.questionsCode = questionsCode;
    },
    questionRequest: questionRequest => {
      this.questionRequest = questionRequest;
    }
  };
  streamResponse = response => {
    const readableStream = response.body
      // eslint-disable-next-line no-undef
      .pipeThrough(new TextDecoderStream())
      .getReader();

    this.set.questionRequest(readableStream);
  };
  qa = async question => {
    try {
      const { publisher, paperID } = this._.reader;

      this._.analytics.track.event("ChatGPT", { question, publisher, paperID });
      this.set.questionRequest(null);

      const response = await fetchFromApiServerRaw({
        path: "question",
        body: {
          paperID,
          publisher,
          question,
          uid: this._.user.uid,
          history: this.questionsPaper
        }
      });

      this.streamResponse(response);
    } catch (error) {
      console.error(error);
      this.set.questionRequest();
    }
  };
}
