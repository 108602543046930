"use client";
import { reaction } from "mobx";
import { fetchFromApiServer, fetchFromApiServerRaw } from "../../service/graph";

export default class Agent {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    reaction(
      () => this._.user.loaded,
      async loaded => {
        if (loaded) {
          const userThreadId = this._.user.profile.threadId;
          const res = await fetch(
            `/api/agent${userThreadId ? `?threadId=${userThreadId}` : ""}`
          );
          const { threadId, history } = await res.json();

          this.set.history(
            this.greeting ? [this.greeting, ...history] : history
          );
          this.set.threadId(threadId);

          if (userThreadId === undefined) {
            this._.user.data.update({ threadId });
          }
          this.set.loaded(true);
        } else {
          this.reset();
        }
      }
    );

    reaction(
      () => [
        this._.user.loaded,
        this._.reader.publisher,
        this._.reader.paperID
      ],
      async ([loaded, publisher, paperID]) => {
        this.set.paperHasBeenRead(false);

        if (loaded && publisher && paperID) {
          const results = await fetchFromApiServer({
            path: `question/availability/${publisher}/${paperID}`
          });

          this.set.paperHasBeenRead(results?.available ?? false);
        }
      }
    );
  }
  set = {
    loaded: (loaded = false) => {
      this.loaded = loaded;
    },
    disable: (disable = false) => {
      this.disable = disable;
    },
    history: (history = []) => {
      this.history = history;
    },
    threadId: threadId => {
      this.threadId = threadId;
    },
    level: (level = 0) => {
      this.level = level;
    },
    session: (session = []) => {
      this.session = session;
    },
    running: (running = false) => {
      this.running = running;
    },
    paperHasBeenRead: (paperHasBeenRead = false) => {
      this.paperHasBeenRead = paperHasBeenRead;
    },
    greeting: greeting => {
      this.greeting = greeting;

      if (greeting) {
        this.message.add(greeting, false);
      }
    },
    needsToSayHi: (needsToSayHi = true) => {
      this.needsToSayHi = needsToSayHi;
    }
  };
  chat = async userInput => {
    try {
      this.set.running(true);
      this._.analytics.track.event("Agent", { message: userInput });

      const res = await fetchFromApiServerRaw({
        path: "agent",
        body: {
          userInput,
          level: this.level,
          session: this.session,
          threadId: this.threadId,
          history: this.history
            .map(({ sources, ...message }) => message)
            .slice(0, 10)
        }
      });

      const history = [...this.history];

      history.unshift({ id: Date.now(), role: "user", text: userInput });
      history.unshift({ id: Date.now() + 1, role: "assistant" });

      this.set.history(history);

      const readableStream = res.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let streamError = false;

      readableStream.closed?.catch(() => {
        streamError = true;
      });

      do {
        try {
          var { done, value } = await readableStream.read();

          if (done === false) {
            const {
              step,
              text,
              sources = [],
              citations = [],
              id = Date.now()
            } = JSON.parse(value.trim().split("\n").pop());

            history[0] = {
              id,
              step,
              citations,
              sources,
              text,
              role: "assistant"
            };

            this.set.history(history);
          }
        } catch (error) {
          console.error(error);
          console.log("error", { value });
        }
      } while (streamError === false && done === false);
    } catch (error) {
      console.error(error);
    } finally {
      this.set.running(false);
    }
  };

  message = {
    add: async (messageObj, save = true) => {
      if (save) {
        const res = await fetch(this.agentUrl, {
          method: "post",
          body: JSON.stringify({ message: messageObj }),
          headers: { "content-type": "application/json" }
        });
        var { messageId } = await res.json();
      } else {
        messageId = Date.now();
      }

      const history = [...this.history];

      messageObj.id = messageId;
      history.unshift(messageObj);

      this.set.history(history);
    },
    delete: async messageId => {
      const history = this.history.filter(message => message.id !== messageId);
      // delete the user message too?
      // history.shift();

      this.set.history(history);

      await fetch(`${this.agentUrl}&messageId=${messageId}`, {
        method: "delete"
      });

      this._.snackbar.notify({ text: "Deleted" });
    }
  };
  get agentUrl() {
    return `/api/agent?threadId=${this._.user.profile.threadId}`;
  }
  get pages() {
    return new Set(this.session.map(({ page }) => page));
  }
}
