import { reaction } from "mobx";
import { wrap } from "comlink";

// import { firestore } from "../firebase";
// import { subscribe } from "../graph";

export default class Playground {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    // let unsubscribeToGraph;

    // reaction(
    //   () => this.mounted && this._.user.loaded,
    //   loaded => {
    //     unsubscribeToGraph?.();

    //     if (loaded) {
    //       // unsubscribeToGraph = subscribe({
    //       //       query: this._.gql.get("playgroundModels"),
    //       //       // pollInterval: 3e3
    //       // })
    //     }
    //   }
    // );

    // let unsubscribe;

    // reaction(
    //   () => this.task,
    //   task => {
    //     unsubscribe?.();

    //     unsubscribe = task
    //       ? firestore.collection(`onnx/${task}/models`).watch(({ docs }) => {
    //           this.set.list(
    //             docs.map(doc => ({
    //               id: doc.id,
    //               modelId: doc.id.replace("_", "/"),
    //               models: Object.entries(doc.data())
    //             }))
    //           );
    //         })
    //       : undefined;
    //   }
    // );

    reaction(
      () => this.models.size,
      async () => {
        for (const [key, model] of this.models.entries()) {
          if (model.worker === undefined) {
            const _worker = new Worker(
              new URL("../model.worker.js", import.meta.url),
              { type: "module", name: key }
            );
            const worker = wrap(_worker);

            model.isRemote =
              model.worksInJavascript === false ||
              [
                "ai-forever/mGPT",
                "microsoft/phi-1",
                "facebook/opt-1.3b",
                "princeton-nlp/Sheared-LLaMA-1.3B"
              ].includes(model.modelId);
            worker.task = this.task;
            worker.isRemote = model.isRemote;
            worker.fileName = model.fileName;
            worker.precision = model.precision;
            worker.params = model.initialParams
              ? { ...model.initialParams }
              : undefined;

            this.models.set(key, { ...model, worker, _worker });
          }
        }
      }
    );
  }
  set = {
    mounted: (mounted = false) => {
      this.mounted = mounted;
    },
    list: (list = []) => {
      this.list = list;
    },
    sync: (sync = true) => {
      this.sync = sync;
    },
    input: input => {
      this.input = input;
    },
    paramSync: (paramSync = true) => {
      this.paramSync = paramSync;
    },
    params: (params = {}) => {
      this.params = params;
    },
    models: (models = new Map()) => {
      this.models = models;
    },
    task: (task = "") => {
      this.task = task;
    },
    hfTask: (hfTask = "") => {
      this.hfTask = hfTask;
    },
    gpu: (gpu = false) => {
      this.gpu = gpu;
    }
  };
  addModel = model => {
    const models = new Map(this.models);
    const key = `${model.modelId}-${Date.now()}`;

    models.set(key, { ...model, key, available: false, loaded: false });
    this.set.models(models);
  };
  run = key => {
    const models = new Map(this.models);
    const runWorker = async ({ ...model }, key) => {
      if (this._.user.isAnonymous && model.worksInJavascript === false) {
        return;
      }
      model.available = false;

      models.set(key, model);
      this.set.models(models);

      this._.analytics.track.event("Model Run", {
        source: "app",
        uid: this._.user.uid,
        modelId: model.modelId
      });

      await model.worker.run();

      model.available = true;
      this.set.models(models);
    };

    if (key) {
      runWorker(models.get(key), key);
    } else {
      this.models.forEach(runWorker);
    }
  };
  get modelsLoaded() {
    return [...this.models.entries()].filter(([, model]) => model.worker);
  }
  get cacheRecent() {
    return "play-recent";
  }
}
